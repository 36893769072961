import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useShallow } from "zustand/shallow";
import logoutIcon from "../../assets/icons/logout.png";
import image from "../../assets/icons/meshilogoback.png";
import SuccessButton from "../../layout/components/buttons/SuccessButton";
import { useImageStore, useProcessStore } from "../../Store";
import { STEP_PAGES, ENV_CONFIG } from "../../utils/Constants";
import ImageViewer from "../ImageViewer/ImageViewer";
import { jwtDecode } from "jwt-decode";
import { uploadImageApi, importDataIntoDB, proxyDownloadApi } from "../../api/ApiService";

const PDFJS = window.pdfjsLib;

const UploadImage = () => {
  const {
    goToStep,
    overwriteStore,
    setOverwriteStore,
    selectedModel,
    setSelectedModel,
  } = useProcessStore(
    useShallow((state) => ({
      goToStep: state.goToStep,
      overwriteStore: state.overwriteStore,
      setOverwriteStore: state.setOverwriteStore,
      selectedModel: state.selectedModel,
      setSelectedModel: state.setSelectedModel,
      setIsLoading: state.setIsLoading,
      setMenuItems: state.setMenuItems,
    }))
  );
  const {
    addNewImageFile,
    addNewImagePath,
    imagePaths,
    deleteImage,
    removeAllImages,
  } = useImageStore(
    useShallow((state) => ({
      addNewImageFile: state.addNewImageFile,
      addNewImagePath: state.addNewImagePath,
      imagePaths: state.imagePaths,
      deleteImage: state.deleteImage,
      removeAllImages: state.removeAllImages,
    }))
  );
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [urlInput, setUrlInput] = useState('');

  const logoutClicked = () => {
    localStorage.removeItem("token");
    removeAllImages();
    navigate("/login");
  };

  const isValidFileType = (file) => {
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'application/pdf'];
    return allowedTypes.includes(file.type);
  };

  const downloadAndProcessUrl = async (url) => {
    try {
      const response = await proxyDownloadApi(url);
      const blob = response.data;
      const contentType = response.headers['content-type'];
      
      if (!contentType || !(['image/', 'application/pdf'].some(type => contentType.includes(type)))) {
        setErrors({
          file: "URL must point to an image or PDF file"
        });
        return;
      }

      const file = new File([blob], url.split('/').pop() || 'downloaded-file', { type: contentType });
      
      if (file.size > 100000000) {
        setErrors({
          file: "File size should be less than 100MB"
        });
        return;
      }

      await processFile(file);
    } catch (error) {
      console.error('Download error:', error);
      setErrors({
        file: "Failed to download or process the URL. Please make sure the URL is accessible."
      });
    }
  };

  const processFile = async (file) => {
    if (!isValidFileType(file)) {
      setErrors({
        file: "Please upload only images (JPEG, PNG, GIF, WebP) or PDF files"
      });
      return;
    }

    if (file.size > 100000000) {
      setErrors({
        file: "File size should be less than 100MB"
      });
      return;
    }

    const isPDF =
      file.type === "application/pdf" ||
      file.name.endsWith(".pdf");
    const isImage = file.type.startsWith("image/");
    if (isPDF) {
      try {
        const arrayBuffer = await file.arrayBuffer();
        const pdfDoc = await PDFJS.getDocument({ data: arrayBuffer }).promise;
        const pageCount = pdfDoc.numPages;

        for (let i = 1; i <= pageCount; i++) {
          const page = await pdfDoc.getPage(i);

          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");

          const viewport = page.getViewport({ scale: 5 });
          canvas.width = viewport.width;
          canvas.height = viewport.height;

          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };

          await page.render(renderContext).promise;

          const blob = await new Promise((resolve) => {
            canvas.toBlob((blob) => resolve(blob), "image/png");
          });
          addNewImagePath(URL.createObjectURL(blob));
          addNewImageFile(blob);
        }
      } catch (err) {
        console.log(err);
      }
    } else if (isImage) {
      addNewImagePath(URL.createObjectURL(file));
      addNewImageFile(file);
    }
  };

  const handleFileChange = async (e) => {
    setErrors({});
    const files = e.target.files;
    
    for (let i = 0; i < files.length; i++) {
      await processFile(files[i]);
    }
  };

  const handleUrlSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    
    if (!urlInput) {
      setErrors({
        file: "Please enter a URL"
      });
      return;
    }

    try {
      new URL(urlInput); // Validate URL format
      await downloadAndProcessUrl(urlInput);
      setUrlInput(''); // Clear the input after processing
    } catch (error) {
      setErrors({
        file: "Please enter a valid URL"
      });
    }
  };

  const handleModelChange = (e) => {
    setSelectedModel(e.target.value);
  };

  const uploadImage = async (e) => {
    if (imagePaths.length === 0) {
      setErrors({
        file: "Please select a file first"
      });
      return;
    }
    goToStep(STEP_PAGES.IMAGE_EDITOR);
  };

  return (
    <div className="w-full h-full overflow-y-auto max-w-4xl mx-auto  rounded relative ">
      <div className="shadow-lg px-8 py-12">
        <img
          className="w-10 h-10 absolute top right-8 hover:cursor-pointer"
          src={logoutIcon}
          onClick={() => logoutClicked()}
          alt="logout icon"
        />
        <img src={image} alt="" className="w-20 h-20" />
        <div className="px-8 py-8 text-center">
          <div className="font-bold text-3xl mb-2 text-gray-800 upload-title">
            Upload Image for Menu extraction
          </div>
          <p className="text-gray-600 text-lg mb-5">
            {/* Extract restaurant menu from an image */}
          </p>
        </div>
        <form className="max-w-lg mx-auto flex flex-col items-center">
          {ENV_CONFIG.CAN_SELECT_LLM_MODEL && (
            <div className="w-full mb-8">
              <label
                htmlFor="ModelName"
                className="block mb-5 text-xl font-semibold text-gray-800"
              >
                Select a model
              </label>
              <select
                id="ModelName"
                value={selectedModel}
                onChange={handleModelChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
              >
                {ENV_CONFIG.AVAILABLE_AI_MODELS.map((model) => {
                  return (
                    <option key={model} value={model}>
                      {model}
                    </option>
                  );
                })}
              </select>
            </div>
          )}

          <div className="w-full mb-8">
            <label
              className="block mb-5 text-xl font-semibold text-gray-800"
              htmlFor="fileInput"
            >
              Upload Menu Image
            </label>
            <input
              onChange={handleFileChange}
              className={`block w-full text-base  border  rounded-lg cursor-pointer ${
                errors.file
                  ? "bg-red-50 border-red-500 text-red-900"
                  : "bg-gray-50 border-gray-300 text-gray-900"
              } focus:outline-none`}
              id="fileInput"
              accept="application/pdf, image/*"
              type="file"
              multiple
            />
            {errors.file && (
              <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                <span className="font-medium">{errors.file}</span>
              </p>
            )}
          </div>
          <div className="w-full mb-8">
            <label
              className="block mb-5 text-xl font-semibold text-gray-800"
              htmlFor="urlInput"
            >
              Or Enter URL
            </label>
            <input
              value={urlInput}
              onChange={(e) => setUrlInput(e.target.value)}
              className={`block w-full text-base  border  rounded-lg cursor-pointer ${
                errors.file
                  ? "bg-red-50 border-red-500 text-red-900"
                  : "bg-gray-50 border-gray-300 text-gray-900"
              } focus:outline-none`}
              id="urlInput"
              type="text"
            />
            <button
              onClick={handleUrlSubmit}
              className="text-base border rounded-lg cursor-pointer border-gray-300 text-gray-900 focus:outline-none"
              style={{ 
                padding: '0.375rem 0.75rem',
                backgroundColor: '#f0f0f0',
                background: 'linear-gradient(to bottom, #f9f9f9, #e3e3e3)'
              }}
            >
              Download and Upload
            </button>
            {errors.file && (
              <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                <span className="font-medium">{errors.file}</span>
              </p>
            )}
          </div>
          <div className="flex w-full items-center mb-8">
            <input
              className={`text-base border rounded-lg cursor-pointer bg-gray-50 border-gray-300 text-gray-900 focus:outline-none`}
              id="overwriteStore"
              type="checkbox"
              checked={overwriteStore}
              onChange={(e) => setOverwriteStore(e.target.checked)}
            />
            <label
              className="ml-4 text-xl font-semibold text-gray-800"
              htmlFor="overwriteStore"
            >
              Overwrite store menu
            </label>
          </div>
          <SuccessButton
            onClick={() => uploadImage()}
            label="Crop images to help AI"
          />
          <ImageViewer
            containerClasssNames="w-full"
            imagePaths={imagePaths}
            onDeleteClicked={(index) => deleteImage(index)}
            deleteAllImages={removeAllImages}
          />
        </form>

        <div className="text-center mt-8">
          <a
            href="https://youtu.be/KQ0XImjkw5Y"
            className="text-blue-500 underline"
          >
            Example video: From menu to website in a few minutes
          </a>
        </div>
      </div>
    </div>
  );
};

export default UploadImage;
